import React, { useState, useRef, useEffect, useContext, useMemo, useCallback } from "react";
import "./ConversationPlayer.scss";
import Conversation from './Conversation';
import Conf from 'Conf';
import { ModelContext } from "providers/ModelProvider";
import { MixContext } from "shared/providers/MixProvider";
import Lien from 'shared/components/Lien';
import { initiales } from 'shared/conversationsUtils';

function ConversationPlayer({ portraitId,step=-1,onTap,follow=true,onDone=()=>null,file=(url)=>url }) {
  const { getDoc,getCollection } = useContext(ModelContext);
  const { triggerSon,mixReady,setContext } = useContext(MixContext);
  const [ lien, setLien ] = useState(null);
  const [ currentConversation, setCurrentConversation ] = useState(null);
  const handleTouch = useCallback((e)=>{
    console.log('handleTouch');
    const node=container.current;
    if (node) {
      const fromBottom=Math.abs(node.scrollTop-(node.scrollHeight-node.clientHeight));
      console.log(fromBottom);
      if (fromBottom<10) {
        onTap();
      }
    }
  },[onTap]);
  const closeLien=useCallback((e)=>{
    e.stopPropagation();
    setLien(null);
  },[setLien]);
  const portrait=useMemo(()=>getDoc(portraitId),[getDoc,portraitId]);
  const conversations=useMemo(()=>getCollection('conversation').filter((o)=>o.portraitId===portraitId),[getCollection,portraitId]);
  const messages=useMemo(()=>getCollection('message').filter((o)=>{
      if(o.msgType==='empty') return false;
      for(let i=0;i<conversations.length;i++) {
        if (o.conversationId===conversations[i]._id) return true;
      }
      return false;
  }),[getCollection,conversations]);
  const interlocuteurs=useMemo(()=>[portrait, ...getCollection('interlocuteur').filter((o)=>o.portraitId===portrait._id)].filter((interlocuteur)=>{
    for(let i=0;i<messages.length;i++) {
      if (messages[i].from===interlocuteur._id) return true;
    }
    return false;
  }),[getCollection,portrait,messages]);
  const container = useRef(null);
  const mainRef = useRef(null);
  const portraitIdCache = useRef(null);
  useEffect(()=>{
    if (portraitIdCache.current!==portraitId) {
      portraitIdCache.current=portraitId;
      setContext({type:'portrait',id:portraitId});
      return ()=>{
        setContext(null);
      }
    }
  },[portraitId,setContext]);
  useEffect(()=>{
    if (currentConversation && mixReady) {
      triggerSon('conversation/'+currentConversation._id);
    }
  },[currentConversation,mixReady,triggerSon]);
  useEffect(()=>{
    if (portraitId && mixReady && step===0) {
      triggerSon('portrait/'+portraitId);
    }
  },[portraitId,mixReady,step,triggerSon]);
  useEffect(()=>{
    const node=mainRef.current;
    if (follow && node && step>0) {
      const parent=node.parentNode;
      parent.scroll(0,parent.scrollHeight-parent.clientHeight);
    }
  },[step,follow,getCollection]);
  useEffect(()=>{
    let rank=0;
    let c=null;
    conversations.forEach((conversation,i)=>{
      if(step===-1 || step>rank) c=conversation;
      rank++;
      const msgs=messages.filter((o)=>o.conversationId===conversation._id);
      rank+=msgs.length;
    });
    if(step>rank) onDone();
    if (c) {
      if (currentConversation===null || currentConversation._id!==c._id) {
        setCurrentConversation(c);
      }
    } else {
      setCurrentConversation(null);
    }
  },[step,setCurrentConversation,currentConversation,onDone,conversations,messages]);
  let rank=0;
  return portrait ? <>
  <div className={'conversation-player-container'+(portrait.administration ? ' administration' : '')} onMouseUp={onTap} onTouchEnd={handleTouch}>
    <div className='conversation-mask'></div>
    <div ref={container} className='conversation-player-scroller'>
      <div className='conversation-player'ref={mainRef}>
        {portrait.photo[0] && <div className='portrait-photo' style={{backgroundImage:`url(${file(Conf.filesUrl+portrait.photo[0].url+'-300')})`}}/>}
        <div className='portrait-nom'>
          {portrait.nom}
        </div>
        <div className='portrait-chapeau'>
          {portrait.chapeau}
        </div>
        {false && step>0 && <div className='portrait-separateur'>
          {interlocuteurs.map((interlocuteur)=><div key={interlocuteur._id} className='portrait-interlocuteur'>
            <div key={interlocuteur._id} className='portrait-interlocuteur-avatar'>
              {initiales(interlocuteur.nom)}
            </div>
            <div className='portrait-interlocuteur-text'>
              <div className='portrait-interlocuteur-nom'>{interlocuteur.nom}</div>
              <div className='portrait-interlocuteur-presentation'>{interlocuteur.presentation}</div>
            </div>
          </div>)}
        </div>}
        {conversations.map((conversation,i)=>{
          if(i>0) {
            const msgs=messages.filter((o)=>o.conversationId===conversations[i-1]._id);
            rank+=msgs.length+1;
          }
          return step===-1 || step>rank ? <Conversation key={conversation._id} index={i} conversation={conversation} step={step} delta={rank} setLien={setLien} file={file}/> : ''
        })}
        {step>0 && <div className='bottom-item'></div>}
      </div>
    </div>
  </div>
  <Lien lien={lien} onClose={closeLien} file={file}/>
  </>: '';
}

export default ConversationPlayer;
