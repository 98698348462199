import React from "react";
import "./Modal.scss";
import Menu from 'shared/components/Menu';

function Modal({content,show,onClose,preventClick=false}) {
  let classes='modal-mask';
  if (show) {
    classes+=' on';
  } else classes+=' off';
  const prevent=(e)=>e.stopPropagation();
  const handleClose=(e)=>{
    if (!preventClick) onClose(e);
  }
  return <div className={classes} onClick={handleClose}>
      <div className='modal-inner' onClick={prevent}>
        {content}
      </div>
      <Menu type='bottom' justify='space-evenly' items={[{className:'close',onClick:handleClose}]}/>
    </div>;
}

export default Modal;
