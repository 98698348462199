import React, { useContext,useState,useEffect } from "react";
import "./Lettre.scss";
import { ModelContext } from "providers/ModelProvider";
import { SettingsContext } from "providers/SettingsProvider";
import { useParams,useNavigate } from "react-router-dom";
import Tapuscrit from 'components/Tapuscrit';
import LettreInner from "shared/components/Lettre";
import Menu from "shared/components/Menu";
import Plus from "components/Plus";
import { MixContext } from "shared/providers/MixProvider";
import { PreloadContext } from "providers/PreloadProvider";

function Lettre() {
  const { id } = useParams();
  const { triggerSon, events } = useContext(MixContext);
  const { appState, setAppState } = useContext(SettingsContext);
  const { getDoc } = useContext(ModelContext);
  const { file } = useContext(PreloadContext);
  const doc=getDoc(id);
  const navigate=useNavigate();
  const [tapuscritShow,setTapuscritShow] = useState(false);
  const [hide,setHide] = useState(false);
  const {x:xDoc,y:yDoc,scale:scaleDoc}=doc;
  const params=appState.lettresParams ? appState.lettresParams[id] || {x:xDoc,y:yDoc,scale:scaleDoc} : {x:xDoc,y:yDoc,scale:scaleDoc};
  const setParams=(x,y,scale)=>{
    setAppState((state)=>{
      return {
        ...state,
        lettresParams:{
          ...state.lettresPositions,
          [id]:{x,y,scale},
        }
      }
    });
  }
  const setPosition=(x,y)=>{
    setParams(x,y,params.scale);
  }
  const setScale=(scale)=>{
    setParams(params.x,params.y,scale);
  }
  const playState=(events.filter((o)=>['lettrePlay/'+id,'lettrePause/'+id,'lettreStop/'+id].indexOf(o.hook)!==-1)[0] || {action:'stop'}).action;
  const play=()=> {
    triggerSon('lettrePlay/'+id);
  }
  const pause=()=> {
    triggerSon('lettrePause/'+id);
  }
  const stop=()=> {
    triggerSon('lettreStop/'+id);
  }
  const hideMenu=()=>setHide(true);
  const showMenu=()=>setHide(false);
  const showMainMenu=()=>setAppState((state)=>{return {...appState,mainMenu:{...appState.mainMenu, show:true}}});
  const topMenu=[{className:'menu',onClick:showMainMenu}];
  useEffect(()=>{
    setAppState((state)=>{return {
      ...state,
      contextMenu:[
        {className:'portrait',onClick:()=>navigate('/player/'+doc.portraitId)},
      ]
    }});
    return ()=>{
      setAppState((state)=>{return {
        ...state,
        contextMenu:[]
      }});
    }
  },[setAppState,id,navigate,doc.portraitId]);
  const [ plus, setPlus ] = useState(false);
  const bottomMenu=[
    {className:'lettres',onClick:()=>navigate('/lettres/'+doc.portraitId)},
    {className:'tapuscrit',onClick:()=>setTapuscritShow(true)},
    {className:'plus',onClick:()=>setPlus(true)},
    {className:'voix',onClick:()=>play(), condition:playState==='stop'},
    {className:'pause',onClick:()=>pause(), condition:playState==='play'},
    {className:'play',onClick:()=>play(), condition:playState==='pause', secondary:{className:'stop',onClick:()=>stop()}},
  ];
  return doc && params ? <div className={'lettre'+(hide ? ' menu-hide' : '')}>
      <LettreInner id={id} onPointerDown={hideMenu} onPointerUp={showMenu} setScale={setScale} setPosition={setPosition} position={params} scale={params.scale} file={file}/>
      <Menu type='top' justify='flex-end' items={topMenu} fullWidth/>
      <Menu type='bottom' justify='space-evenly' items={bottomMenu}/>
      <Tapuscrit lettre={doc} show={tapuscritShow} onClose={()=>setTapuscritShow(false)}/>
      <Plus portraitId={doc.portraitId} show={plus} onClose={()=>setPlus(false)} file={file}/>
  </div> : '';
}

export default Lettre;
