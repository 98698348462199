import React, { useRef, useEffect } from 'react';
import "./Scaler.scss";
import PlayerConf from 'shared/Conf';

const Scaler = () => {
  const el=useRef(null);
  useEffect(()=>{
    const root = document.documentElement;
    PlayerConf.css.forEach(rule => root.style.setProperty('--'+rule.key, rule.value));
    const handleResize=()=>{
      if (el.current) {
        const { width,height } = el.current.getBoundingClientRect();
        const ratio=height/width;
        const R=PlayerConf.height/PlayerConf.width;
        let scale=1;
        let left=0;
        let top=0;
        if (ratio<R) {
          scale=height/PlayerConf.height;
        } else {
          scale=width/PlayerConf.width;
        }
        left=(width-PlayerConf.width*scale)/2;
        top=(height-PlayerConf.height*scale)/2;
        root.style.setProperty('--pixelSize', (1.2/scale)+'px');
        root.style.setProperty('--ratio', scale);
        root.style.setProperty('--left', left+'px');
        root.style.setProperty('--top', top+'px');
        root.style.setProperty('--actualWidth', (PlayerConf.width*scale)+'px');
        root.style.setProperty('--actualHeight', (PlayerConf.height*scale)+'px');
        root.style.setProperty('--reverseWindowWidth', (window.innerWidth/scale)+'px');
        root.style.setProperty('--reverseWindowHeight', (window.innerHeight/scale)+'px');
      }
      root.style.setProperty('--screenHeight', window.innerHeight+"px");
      root.style.setProperty('--screenWidth', window.innerWidth+"px");
    }
    window.addEventListener('resize',handleResize);
    handleResize();
    return ()=>{
      window.removeEventListener('resize',handleResize);
    }
  },[]);
  return <div className="scaler" ref={el}>
    <div className="frame"></div>
  </div>;
}
export default Scaler;
