import React from 'react';
import "./Menu.scss";

const Menu = ({items, type='top', fullWidth=false, justify='space-evenly'}) => {
  return <div className={'menu-container-'+type}>
      <div className={'menu-inner'+(fullWidth ? ' full-width':'')} style={{justifyContent:justify}}>
        {items.filter((o)=>o.condition===undefined || o.condition).map((item)=>{
          if (item.secondary) return <div key={item.id || item.className} className="combo">
            <div className={'menu-item menu-'+item.className} onClick={item.onClick}></div>
            <div className={'menu-item menu-item-secondary menu-'+item.secondary.className} onClick={item.secondary.onClick}></div>
          </div>;
          return <div key={item.id || item.className} className={'menu-item menu-'+item.className} onClick={item.onClick}></div>;
        })}
      </div>
    </div>;
}
export default Menu;
