import React, {useLayoutEffect, useContext, useState, useCallback, useMemo} from "react";
import './App.css';
import 'shared/styles.scss';

import Landing from 'components/Landing';
import Themes from 'components/Themes';
import Home from 'components/Home';
import Loader from 'components/Loader';
import Player from 'components/Player';
import Lettres from 'components/Lettres';
import Lettre from 'components/Lettre';
import MainMenu from 'components/MainMenu';
import { PreloadContext } from "providers/PreloadProvider";
import { MixContext } from "shared/providers/MixProvider";
import Scaler from "shared/components/Scaler";

import {
  Routes,
  Route,
} from "react-router-dom";

function App() {
  const [ animDone,setAnimDone ] = useState(false);
  const { assetsLoaded,assetsReady } = useContext(PreloadContext);
  const { mixLoaded,mixCanPlay,needAction } = useContext(MixContext);
  useLayoutEffect(()=>{
    const handleContextMenu=(event)=>{
       event.preventDefault();
       event.stopPropagation();
       return false;
    }
    window.addEventListener('contextmenu',handleContextMenu);
    return ()=>{
      window.removeEventListener('contextmenu',handleContextMenu);
    }
  },[]);
  const handleDone=useCallback(()=>setAnimDone(true),[setAnimDone]);
  const ready=useMemo(()=>mixCanPlay && assetsReady,[mixCanPlay,assetsReady]);
  const loaded=useMemo(()=>(assetsLoaded + mixLoaded)/2,[assetsLoaded,mixLoaded]);
  return (
    <>
      <Scaler/>
      {ready && animDone ? <div className="app">
        <Routes>
          <Route path="/themes" element={<Themes />} />
          <Route path="/lettres/:id" element={<Lettres />} />
          <Route path="/lettre/:id" element={<Lettre />} />
          <Route path="/player/:id" element={<Player />} />
          <Route path="/dev" element={<Home/>} />
          <Route path="/" element={<Landing />} />
          <Route path="*" element={<Landing />} />
        </Routes>
        <MainMenu/>
      </div>: <Loader loaded={loaded} onDone={handleDone} needAction={needAction}/> }
    </>
  );
}

export default App;
