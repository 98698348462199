import React, { useState, createContext, useContext, useEffect, useCallback, useMemo, useRef} from 'react';
import Conf from 'Conf';
import { ModelContext } from "providers/ModelProvider";
import Player from 'shared/providers/Player';
import Preload from 'preload-it';
import fetch from "shared/providers/CustomFetch";
import Queue from "queue";
import produce from 'immer';

export const MixContext = createContext({});

const audioContext = new (window.AudioContext || window.webkitAudioContext)();
const masterMute = audioContext.createGain();
masterMute.connect(audioContext.destination);
const master = audioContext.createGain();
master.connect(masterMute);
const tracksVolume={};
[...Array(20).keys()].forEach((idx) => {
  tracksVolume['track-'+idx]=audioContext.createGain();
});
const fadeDuration=2000;

const players={};
const files=[];
let cachedSons=[];
const preload = Preload();
preload.fetch=fetch;
preload.onerror = item => {
  console.log(item);
}
const cacheName='v1-front-suppliques';

let context=null;

const fileSizeSI=(a,b,c,d,e)=>{
 return (b=Math,c=b.log,d=1e3,e=c(a)/c(d)|0,a/b.pow(d,e)).toFixed(2)
 +' '+(e?'kMGTPEZY'[--e]+'B':'Bytes')
}

const needEvts=(hook)=> hook.startsWith('lettrePlay/') ||  hook.startsWith('lettrePause/') || hook.startsWith('lettreStop/');

const MixProvider = ({children}) => {
  console.log('MixProvider render');
  const { getCollection } = useContext(ModelContext);
  const [ needAction, setNeedAction ] = useState(false);
  const [ mixCanPlay, setMixCanPlay ] = useState(false);
  const [ mixReady, setMixReady ] = useState(false);
  const [ settingsReady, setSettingsReady ] = useState(false);
  const [ muteAll, setMuteAll ] = useState(false);
  const [ mixLoaded, setMixLoaded ] = useState(0);
  const [ volume, setVolume ] = useState(0);
  const [ totalSize, setTotalSize ] = useState(0);
  const [ events, setEvents ] = useState([]);
  const [ cachedFiles,setCachedFiles ] = useState([]);
  const cache=useRef(null);
  //list already cached files keys
  useEffect(()=>{
    if('caches' in window) {
      caches.open(cacheName).then((c) => {
        cache.current=c;
        c.keys().then(function(response) {
          setCachedFiles(response);
        });
      });
    }
  },[]);

  const emit=useCallback((hook,details={})=>setEvents((evts)=>produce(evts,(draft)=>{
    const evtIdx=draft.findIndex((o)=>o.hook===hook);
    if (evtIdx!==-1) draft.splice(evtIdx,1);
    draft.splice(0,0,{hook,...details});
  })),[setEvents]);
  const fadeIn=useCallback((son,listener,duration=fadeDuration)=>{
    const player=players[listener.track] ? players[listener.track][son._id] : null;
    if (player) {
      console.log('fadein',player.titre);
      if (listener.action==='play out') {
        const clone=new Player({
          audioContext,
          master,
          trackVolume: player.main.trackVolume,
          src: player.main.src,
          loop:player.main.loop,
          volume:player.main.volume,
        });
        clone.status=null;
        player.clones.push(clone);
        clone.once('end',()=>{
          const idx=player.clones.indexOf(clone);
          player.clones.splice(idx,1);
        });
        if (player.fadeIn){
          clone.volume=0;
          clone.play();
          clone.fade(player.volume,duration);
        } else {
          clone.volume=player.volume;
          clone.play();
        }
      } else {
        Object.keys(players[listener.track]).forEach((sonId, i) => {
          if (sonId!==son._id) {
            let p=players[listener.track][sonId];
            if (p.main.playing) {
              p.main.status='fadingOut';
              p.main.off('load');
              p.main.off('fade');
              p.main.fade(0,duration);
              p.main.once('fade',()=>{
                p.main.status=null;
                p.main.stop();
                if (needEvts(listener.hook)) emit(listener.hook,{action:'stop',sondId:son._id});
              });
            }
          }
        });
        player.main.off('load');
        player.main.off('fade');
        if (!player.main.playing) {
          player.main.off('end');
          player.main.status=null;
          player.main.once('end',()=>{
            if (needEvts(listener.hook)) emit(listener.hook,{action:'stop',sondId:son._id});
          });
          if (player.fadeIn){
            //console.log('setVolume');
            player.main.volume=0;
            //console.log('play');
            player.main.play();
            if (needEvts(listener.hook)) emit(listener.hook,{action:'play',sondId:son._id});
            //console.log('fade');
            player.main.fade(player.volume,duration);
          } else {
            player.main.volume=0;
            //console.log('setVolume',player.volume);
            player.main.volume=player.volume;
            //console.log('play');
            player.main.play();
            if (needEvts(listener.hook)) emit(listener.hook,{action:'play',sondId:son._id});
            player.main.fade(player.volume,duration);
          }
        } else {
          player.main.status=null;
          //console.log('fade');
          player.main.fade(player.volume,duration);
        }
      }
    }
  },[emit]);
  const fadeOut=useCallback((son,listener,stop=false,duration=fadeDuration)=>{
    const player=players[listener.track] ? players[listener.track][son._id] : null;
    if (player) {
      if (!player.loaded) {
        player.main.once('load',player.main.stop);
      }
      if (listener.action==='play out') {
        player.clones.forEach((clone, i) => {
          if (clone.playing || clone.playasked) {
            if (clone.status!=='fadingOut') {
              console.log('fadeOut',player.titre);
              //console.log('fadeOut',clone);
              clone.status='fadingOut';
              clone.off('fade');
              clone.fade(0,duration);
              clone.once('fade',()=>{
                clone.status=null;
                clone.stop();
              });
            }
          }
        });
      } else {
        if (player.main.playing || player.main.playasked) {
          player.main.off('end');
          if (player.main.status!=='fadingOut') {
            console.log('fadeOut',player.titre);
            player.main.status='fadingOut';
            player.main.off('fade');
            if (stop) {
              player.main.fade(0,duration);
              player.main.once('fade',()=>{
                player.main.status=null;
                player.main.stop();
                if (needEvts(listener.hook)) emit(listener.hook,{action:'stop',sondId:son._id});
              });
            } else {
              player.main.fade(0,duration);
              player.main.once('fade',()=>{
                player.main.status=null;
                player.main.pause();
                if (needEvts(listener.hook)) emit(listener.hook,{action:'pause',sondId:son._id});
              });
            }
          }
        } else {
          if (stop) {
            console.log('stop');
            player.main.status=null;
            player.main.stop();
            if (needEvts(listener.hook)) emit(listener.hook,{action:'stop',sondId:son._id});
          }
        }
      }
    }
  },[emit]);
  const mute=()=>{
    masterMute.gain.cancelScheduledValues(audioContext.currentTime);
    masterMute.gain.setValueAtTime(masterMute.gain.value, audioContext.currentTime);
    masterMute.gain.linearRampToValueAtTime(0, audioContext.currentTime+0.5);
  }
  const unMute=()=>{
    masterMute.gain.cancelScheduledValues(audioContext.currentTime);
    masterMute.gain.setValueAtTime(masterMute.gain.value, audioContext.currentTime);
    masterMute.gain.linearRampToValueAtTime(1, audioContext.currentTime+0.5);
  }
  useEffect(()=>{
    window.addEventListener('blur',mute);
    window.addEventListener('focus',unMute);
    return ()=>{
      window.removeEventListener('blur',mute);
      window.removeEventListener('focus',unMute);
    }
  },[]);
  const sons=useMemo(()=>getCollection('son'),[getCollection]);
  const settings=useMemo(()=>getCollection('settings'),[getCollection]);
  const conversations=useMemo(()=>getCollection('conversation'),[getCollection]);
  const messages=useMemo(()=>getCollection('message'),[getCollection]);
  const lettres=useMemo(()=>getCollection('lettre'),[getCollection]);
  const themes=useMemo(()=>getCollection('theme'),[getCollection]);
  const triggerSon=useCallback((hook)=>{
    console.log(hook);
    const toTrigger=[hook];
    const tab=hook.split('/');
    if (tab[0]==='lettre' && tab.length===2) {
      toTrigger.push('lettre');
    }
    if (tab[0]==='theme' && tab.length===2) {
      toTrigger.push('theme');
    }
    if (tab[0]==='conversation' && tab.length===2) {
      const conversation=conversations.find((o)=>o._id===tab[1]);
      toTrigger.push('portrait/'+conversation.portraitId+'/conversation');
      toTrigger.push('conversation');
    }
    if (tab[0]==='message' && tab.length===2) {
      const message=messages.find((o)=>o._id===tab[1]);
      const conversation=conversations.find((o)=>message && o._id===message.conversationId);
      if (conversation) {
        toTrigger.push('conversation/'+conversation._id+'/message');
        toTrigger.push('portrait/'+conversation.portraitId+'/message');
        toTrigger.push('message');
      }
    }
    cachedSons.forEach((son, i) => {
      son.listeners.forEach((listener, i) => {
        if(toTrigger.indexOf(listener.hook)!==-1) {
          if (listener.action==='play' || listener.action==='play out') {
            fadeIn(son,listener);
          }
          if (listener.action==='pause') {
            fadeOut(son,listener);
          }
          if (listener.action==='stop') {
            fadeOut(son,listener,true);
          }
          if (listener.action==='play cut') {
            fadeIn(son,listener,50);
          }
          if (listener.action==='pause cut') {
            fadeOut(son,listener,false,50);
          }
          if (listener.action==='stop cut') {
            fadeOut(son,listener,true,50);
          }
        }
      });
    });
  },[conversations,messages,fadeIn,fadeOut]);
  const updateSons=useCallback((items)=>{
    if (sons && sons.length>0) {
      sons.forEach((son, i) => {
        if (son.fichier.length>0) {
          let path=Conf.filesUrl+son.fichier[0].url;
          const item=items.find((o)=>o.url===path);
          if (item) {
            son.listeners.forEach((listener, i) => {
              if (!players[listener.track]) players[listener.track]={};
              if (!players[listener.track][son._id]) {
                players[listener.track][son._id]={
                  main:new Player({
                    audioContext,
                    master,
                    trackVolume:tracksVolume[listener.track],
                    src: item.blobUrl,
                    loop:Boolean(son.loop),
                    volume:isNaN(son.volume) ? 1 : son.volume,
                    onplayerror:(err)=>{
                      console.log('play error',err);
                    }
                  }),
                  item:item,
                  titre:son.titre,
                  url:son.fichier[0].url,
                  volume:isNaN(son.volume) ? 1 : son.volume,
                  fadeIn:Boolean(son.fadeIn),
                  clones:[],
                };
              }
            });
          }
        }
      });
    }
  },[sons]);
  preload.oncomplete = items => {
    updateSons(items);
    items.forEach((item, i) => {
      if (item.blobUrl) {
        const idx=files.findIndex((o)=>o.url===item.url);
        if (idx!==-1) {
          files[idx]=item;
        }
      }
    });
    setTotalSize(files.reduce((total,f)=>{
      return f.size ? f.size+total : total;
    },0));
    console.log('players updated',players);
    setMixReady(true);
    console.log('sound preload done');
  }
  preload.onprogress = event => {
    setMixLoaded(event.progress);
    //console.log(event.progress + '%');
  }
  preload.onfetched = item => {
    //console.log(item);
  }
  useEffect(()=>{
    if (settingsReady) {
      console.log('rebuilt players');
      sons.forEach((son, i) => {
        son.listeners.forEach((listener, i) => {
          if (players[listener.track] && players[listener.track][son._id]){
            let player=players[listener.track][son._id];
            let volume=isNaN(son.volume) ? 1 : son.volume;
            if (player.volume!==volume) {
              console.log('update Volume');
              player.volume=volume;
              if (player.main.status!=='fadingOut') player.main.fade(volume,50);
              player.clones.forEach((clone, i) => {
                if (clone.status!=='fadingOut') clone.fade(volume,50);
              });
            }
            if (player.loop!==son.loop) {
              console.log('update Loop');
              player.loop=son.loop;
              player.main.loop=player.loop;
              player.clones.forEach((clone, i) => {
                clone.loop(player.loop);
              });
            }
            if (player.fadeIn!==son.fadeIn) {
              console.log('update FadeIn');
              player.fadeIn=Boolean(son.fadeIn);
            }
            if (son.fichier.length>0) {
              if (player.url!==son.fichier[0].url) {
                console.log('url changed');
                player.main.stop();
                if (needEvts(listener.hook)) emit(listener.hook,{action:'stop',sondId:son._id});
                player.clones.forEach((clone, i) => {
                  clone.stop();
                  if (needEvts(listener.hook)) emit(listener.hook,{action:'stop',sondId:son._id});
                });
                let path=Conf.filesUrl+player.url;
                let pathIdx=files.findIndex((o)=>o.url===path);
                if (pathIdx!==-1) files.splice(pathIdx,1);
                delete players[listener.track][son._id];
              }
            } else {
              console.log('fichier supprimé');
              player.main.stop();
              if (needEvts(listener.hook)) emit(listener.hook,{action:'stop',sondId:son._id});
              player.clones.forEach((clone, i) => {
                clone.stop();
                if (needEvts(listener.hook)) emit(listener.hook,{action:'stop',sondId:son._id});
              });
              let path=Conf.filesUrl+player.url;
              let pathIdx=files.findIndex((o)=>o.url===path);
              if (pathIdx!==-1) files.splice(pathIdx,1);
              delete players[listener.track][son._id];
            }
          }
        });
      });
      if (sons.length>0) {
        cachedSons=[...sons];
        const newFiles=[];
        sons.forEach((son, i) => {
          if (son.fichier.length>0 && son.listeners.length>0) {
            let path=Conf.filesUrl+son.fichier[0].url;
            if (files.findIndex((o)=>o.url===path)===-1) {
              files.push({url:path});
              newFiles.push(path);
            }
          }
        });
        if (newFiles.length>0) {
          setMixReady(false);
          const toPreload=[];
          const list=[];
          const q=new Queue({concurrency:1});
          newFiles.map((url)=>{return {url}}).forEach((item)=>{
            q.push((cb)=>{
              const cachedFile=cachedFiles.find((o)=>o.url.endsWith(item.url));
              if(cachedFile) {
                let size=0;
                cache.current.match(item.url)
                .then(response => response.blob())
                .then(blob => {
                  size=blob.size;
                  return URL.createObjectURL(blob)
                })
                .then((blobUrl)=>{
                  list.push({...item, blobUrl, size});
                  cb();
                })
              } else {
                console.log('file not found',item.url);
                toPreload.push(item);
                cb();
              }
            });
          });
          q.on('end',()=>{
            updateSons(list);
            list.forEach((item, i) => {
              if (item.blobUrl) {
                const idx=files.findIndex((o)=>o.url===item.url);
                if (idx!==-1) {
                  files[idx]=item;
                }
              }
            });
            setTotalSize(files.reduce((total,f)=>{
              return f.size ? f.size+total : total;
            },0));
            if (toPreload.length>0) preload.fetch(toPreload.map((o)=>o.url));
            else {
              setMixReady(true);
              setMixLoaded(100);
            }
          });
          q.start();
        }
      }
    }
  },[sons,setMixReady,settingsReady,emit,updateSons,cachedFiles]);
  const setContext=useCallback((c)=>{
    console.log('context',c);
    if (
      (
        c!==null && (
          context===null
          || c.type!==context.type
          || c.id!==context.id
        )
      )
      || (c===null && context!==null)
      ) {
      context=c;
      const aSons=[];
      if (context) {
        if (context.type==='landing') {
          cachedSons.forEach((son)=>{
            son.listeners.forEach((listener, i) => {
              if (listener.hook) {
                const tab=listener.hook.split('/');
                let test=false;
                if (tab[0].startsWith('landing')) test=true;
                if (test) aSons.push({sonId:son._id,listenerId:listener._id});
              }
            });
          });
        }
        if (context.type==='portrait') {
          const portraitConversations=conversations.filter((o)=>o.portraitId===context.id);
          let messagesAll=[];
          portraitConversations.forEach((conversation) => {
            const conversationMessages=messages.filter((o)=>o.conversationId===conversation._id);
            messagesAll=[...messagesAll,...conversationMessages];
          });
          cachedSons.forEach((son)=>{
            son.listeners.forEach((listener, i) => {
              if (listener.hook) {
                const tab=listener.hook.split('/');
                let test=false;
                if (tab[0]==='portrait' && tab[1]===context.id) test=true;
                if (tab[0]==='conversation' && portraitConversations.findIndex((o)=>o._id===tab[1])!==-1) test=true;
                if (tab[0]==='message' && messagesAll.findIndex((o)=>o._id===tab[1])!==-1) test=true;
                if (test) aSons.push({sonId:son._id,listenerId:listener._id});
              }
            });
          });
        }
        if (context.type==='themes') {
          cachedSons.forEach((son)=>{
            son.listeners.forEach((listener, i) => {
              if (listener.hook) {
                const tab=listener.hook.split('/');
                let test=false;
                if (tab[0]==='themes') test=true;
                if (tab[0]==='theme') test=true;
                if (tab[0]==='theme' && themes.findIndex((o)=>o._id===tab[1])!==-1) test=true;
                if (test) aSons.push({sonId:son._id,listenerId:listener._id});
              }
            });
          });
        }
        if (context.type==='lettre') {
          cachedSons.forEach((son)=>{
            son.listeners.forEach((listener, i) => {
              if (listener.hook) {
                const tab=listener.hook.split('/');
                let test=false;
                if (tab[0]==='lettre') test=true;
                if (tab[0]==='lettre' && lettres.findIndex((o)=>o._id===tab[1])!==-1) test=true;
                if (test) aSons.push({sonId:son._id,listenerId:listener._id});
              }
            });
          });
        }
      }
      if (mixReady) {
        cachedSons.forEach((son, i) => {
          son.listeners.forEach((listener, i) => {
            const activeIdx=aSons.findIndex((o)=>o.sonId===son._id && o.listenerId===listener._id);
            if (activeIdx===-1) {
              fadeOut(son,listener);
            }
          });
        });
      }
    }
  },[conversations,messages,lettres,themes,mixReady,fadeOut]);
  useEffect(()=>{
    if (muteAll) {
      master.gain.cancelScheduledValues(audioContext.currentTime+0.01);
      master.gain.setValueAtTime(master.gain.value, audioContext.currentTime+0.01);
      master.gain.linearRampToValueAtTime(0, audioContext.currentTime+0.01+0.5);
    } else {
      master.gain.cancelScheduledValues(0);
      master.gain.setValueAtTime(master.gain.value, audioContext.currentTime+0.01);
      master.gain.linearRampToValueAtTime(volume, audioContext.currentTime+0.01+0.5);
    }
  },[muteAll,volume]);
  useEffect(()=>{
    if (settings[0] && settings[0].sons && settings[0].sons.tracksVolume) {
      setVolume(settings[0].sons.mainVolume);
      master.gain.setValueAtTime(settings[0].sons.mainVolume,audioContext.currentTime);
      Object.keys(tracksVolume).forEach((key) => {
        tracksVolume[key].gain.setValueAtTime(settings[0].sons.tracksVolume[key],audioContext.currentTime);
      });
    }
    setSettingsReady(true);
  },[settings,setVolume,setSettingsReady]);
  useEffect(()=>{
    if (mixReady) {
      const activeTracks=Object.keys(players);
      if (activeTracks.length>0) {
        const trackSons=Object.keys(players[activeTracks[0]]);
        if (trackSons.length>0) {
          if (audioContext.state==='suspended') {
            setNeedAction(true);
            const handler=()=>{
              audioContext.resume();
              setMixCanPlay(true);
              setNeedAction(false);
              document.removeEventListener('click',handler);
            }
            document.addEventListener('click',handler)
          } else {
            setMixCanPlay(true);
          }
        }
      }
    }
  },[mixReady, setMixCanPlay, setNeedAction]);
  return <MixContext.Provider value={{totalSize:fileSizeSI(totalSize), needAction, mixCanPlay, files, events, setContext, triggerSon, mixLoaded, mixReady, muteAll, setMuteAll, sons, players}}>
      {children}
  </MixContext.Provider>;
}
export default MixProvider;
