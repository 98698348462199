import React, {useContext, useState, useEffect } from "react";
import "./Landing.scss";
import {
  useNavigate
} from "react-router-dom";
import { ModelContext } from "providers/ModelProvider";
import { PreloadContext } from "providers/PreloadProvider";
import { MixContext } from "shared/providers/MixProvider";
import Bulle from "shared/components/ConversationPlayer/Bulle";


function Landing() {
  const { triggerSon,mixReady,setContext } = useContext(MixContext);
  const { getCollection } = useContext(ModelContext);
  const { file } = useContext(PreloadContext);
  const texts=getCollection('interface');
  const text1=texts.find((o)=>o.slug==='landing_bulle1');
  const text2=texts.find((o)=>o.slug==='landing_bulle2');
  const [ step, setStep ] = useState(0);
  const navigate=useNavigate();
  useEffect(()=>{
    setContext({type:'landing'});
    return ()=>{
      setContext(null);
    }
  },[setContext]);
  useEffect(()=>{
    if (step>2) navigate('/themes');
  },[step,navigate])
  useEffect(()=>{
    if (mixReady) {
      triggerSon('landing');
    }
  },[mixReady,triggerSon]);
  useEffect(()=>{
    if (mixReady && step===1) {
      triggerSon('landing_bulle1');
    }
    if (mixReady && step===2) {
      triggerSon('landing_bulle2');
    }
  },[step,mixReady,triggerSon]);
  return <div className='landing' onClick={()=>setStep(step=>step+1)} onTouchEnd={()=>setStep(step=>step+1)}>
    <div className='landing-mask'></div>
    <div className='logo'>
      <img src={file('/app-assets/logo.svg')} alt='suppliques stories'/>
      <div className="landing-messages">
        <div className='landing-message landing-message-right' style={{transform:step>=1 ? 'scale(1)' : 'scale(0)'}}>
          <div className='landing-message-content'>
            <Bulle text={text1 ? text1.texte_long : ''} side='right' file={file}/>
          </div>
        </div>
        <div className='landing-message landing-message-left' style={{transform:step>=2 ? 'scale(1)' : 'scale(0)'}}>
          <div className='landing-message-content'>
            <Bulle text={text2 ? text2.texte_long : ''} side='left' file={file}/>
          </div>
        </div>
      </div>
    </div>
  </div>;
}

export default Landing;
