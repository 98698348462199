import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ModelProvider from "providers/ModelProvider";
import SettingsProvider from "providers/SettingsProvider";
import MixProvider from "shared/providers/MixProvider";
import PreloadProvider from "providers/PreloadProvider";
import { BrowserRouter } from "react-router-dom";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <ModelProvider>
        <SettingsProvider>
          <MixProvider>
            <PreloadProvider>
              <App />
            </PreloadProvider>
          </MixProvider>
        </SettingsProvider>
      </ModelProvider>
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if ('serviceWorker' in navigator) {
  console.log('serviceWorker');
  navigator.serviceWorker
    .register('/service-worker.js', { scope: '/' })
    .then(function(registration) {
      console.log("Service Worker Registered");
    })
    .catch(function(err) {
      console.log("Service Worker Failed to Register", err);
    })

}
