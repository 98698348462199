import React, {useContext, useEffect, useMemo, useCallback } from "react";
import "./Themes.scss";
import {
  Link,
} from "react-router-dom";
import { ModelContext } from "providers/ModelProvider";
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import SwiperCore, {
  Navigation
} from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/modules/navigation/navigation.scss';
import Conf from 'Conf';
import { MixContext } from "shared/providers/MixProvider";
import Menu from "shared/components/Menu";
import { SettingsContext } from "providers/SettingsProvider";
import { PreloadContext } from "providers/PreloadProvider";

SwiperCore.use([Navigation]);

function Themes() {
  const { triggerSon,mixReady,setContext } = useContext(MixContext);
  const { getCollection } = useContext(ModelContext);
  const { appState, setAppState } = useContext(SettingsContext);
  const { file } = useContext(PreloadContext);
  const portraits=useMemo(()=>getCollection('portrait'),[getCollection]);
  const themes=useMemo(()=>getCollection('theme').filter((theme)=>{
    const ps=portraits.filter((o)=>o.themeId===theme._id);
    return ps.length>0;
  }),[getCollection,portraits]);
  const themeId=useMemo(()=>appState.themeId || (themes[0] ? themes[0]._id : null),[appState.themeId,themes]);
  const themeIdx=useMemo(()=>themeId ? themes.findIndex((o)=>o._id===themeId) : null,[themes,themeId]);
  const list=useMemo(()=>portraits.filter((o)=>o.themeId===themeId),[portraits,themeId]);
  const portraitId=useMemo(()=>appState.portraitId ? appState.portraitId[themeId] : null,[appState.portraitId,themeId]);
  const portraitIdx=useMemo(()=>portraitId ? list.findIndex((o)=>o._id===portraitId) : null,[list,portraitId]);
  const setThemeId=useCallback((id)=>{
    setAppState((state)=>{return {...state,themeId:id}});
  },[setAppState]);
  const setPortraitId=useCallback((id)=>{
    if (themeId) setAppState((state)=>{return {...state,portraitId:{...state.portraitId,[themeId]:id}}});
  },[setAppState,themeId]);
  useEffect(()=>{
    setContext({type:'themes'});
    return ()=>{
      setContext(null);
    }
  },[setContext]);
  const handleSlideChange=useCallback((swiper)=>{
    if (themes.length>0) setThemeId(themes[(swiper.activeIndex) % themes.length]._id);
  },[setThemeId,themes]);
  const handlePortraitSlideChange=useCallback((swiper)=>{
    if (list.length>0) setPortraitId(list[(swiper.activeIndex) % list.length]._id);
  },[setPortraitId,list]);
  useEffect(()=>{
    if (mixReady) {
      triggerSon('themes');
    }
  },[mixReady,triggerSon]);
  useEffect(()=>{
    if (mixReady && themeId) {
      triggerSon('theme/'+themeId);
    }
  },[themeId,mixReady,triggerSon]);
  const showMainMenu=()=>setAppState((state)=>{return {...appState,mainMenu:{...appState.mainMenu, show:true}}});
  const topMenu=[{className:'menu',onClick:showMainMenu}];
  return <div className='themes'>
    <div className='themes-mask'></div>
    <div className='themes-inner'>
      <div className='themes-chooser'>
          <Swiper
            navigation
            spaceBetween={0}
            slidesPerView={1}
            initialSlide={themeIdx ? themeIdx : 0}
            onSlideChange={handleSlideChange}
          >
            {themes.map((theme)=><SwiperSlide key={theme._id}>
                <div className="theme-inner">
                {theme.titre}
                </div>
              </SwiperSlide>
            )}
          </Swiper>
      </div>
      <div className='portraits-chooser'>
          <Swiper
            key={themeId}
            navigation
            spaceBetween={0}
            slidesPerView={3}
            initialSlide={portraitIdx ? portraitIdx : (list.length>2 ? 1 : 0)}
            centeredSlides={list.length>2}
            centerInsufficientSlides={list.length<=2}
            onSlideChange={handlePortraitSlideChange}
          >
            {list.map((portrait)=><SwiperSlide key={portrait._id}>
              <Link to={'/player/'+portrait._id}>
              <div className='portrait-slide'>
                {portrait.photo[0] && <div className='portrait-photo' style={{backgroundImage:`url(${file(Conf.filesUrl+portrait.photo[0].url+'-300')})`}}/>}
                <div className='portrait-nom'>{portrait.nom}</div>
              </div>
              </Link>
            </SwiperSlide>)}
          </Swiper>
      </div>
    </div>
    <Menu type='top' justify='flex-end' items={topMenu} fullWidth/>
  </div>;
}

export default Themes;
