import React, { useContext,useState,useEffect,useMemo } from "react";
import "./Conversation.scss";
import { ModelContext } from "providers/ModelProvider";
import { prenom } from 'shared/conversationsUtils';
import { reactStringReplace } from 'shared/Utils';
import { MixContext } from "shared/providers/MixProvider";
import ConversationHeader from './ConversationHeader';
import Bulle from './Bulle';

function Conversation({ conversation,step,delta,container,setLien,file=(url)=>url}) {
  const { getDoc,getCollection } = useContext(ModelContext);
  const { triggerSon } = useContext(MixContext);
  const [ currentMessage, setCurrentMessage ] = useState(null);
  const portrait=useMemo(()=>getDoc(conversation.portraitId),[conversation.portraitId,getDoc]);
  const messages=useMemo(()=>getCollection('message').filter((o)=>o.conversationId===conversation._id),[getCollection,conversation]);
  const interlocuteurs=useMemo(()=>[portrait,...getCollection('interlocuteur')].filter((o)=>{
    let res=false;
    messages.forEach((message, i) => {
      if (message.from===o._id) res=true;
    });
    return res;
  }),[portrait,getCollection,messages]);
  const openLien=(e,id)=>{
    e.stopPropagation();
    const l=getCollection('lien').find((o)=>o.slug===id);
    if (l) {
      setLien(l);
    }
  }
  const formatText=(text) => {
    let res=reactStringReplace(text, /(\[([^>\]]+)>>([^\]]+)\])/gm, (matches, i) => {
      //console.log(matches);
      return <span className='lien' onClick={(e)=>openLien(e,matches[2])} onMouseUp={(e)=>e.stopPropagation()} onTouchEnd={(e)=>e.stopPropagation()} key={i}>{matches[1]}</span>;
    });
    return res;
  }

  useEffect(()=>{
    let rank=delta;
    let m=null;
    const messages=getCollection('message').filter((o)=>o.conversationId===conversation._id && o.msgType!=='empty');
    messages.forEach((message,i)=>{
      rank++;
      if(step===-1 || step>rank) m=message;
    });
    if (currentMessage===null || (m && currentMessage._id!==m._id)) {
      setCurrentMessage(m);
    }
  },[step,conversation._id,delta,setCurrentMessage,currentMessage,getCollection]);
  useEffect(()=>{
    if (currentMessage) {
      triggerSon('message/'+currentMessage._id);
    }
  },[currentMessage,triggerSon]);
  //console.log(messages);
  let rank=delta;
  return <div className='conversation-player-conversation'>
    <ConversationHeader date={conversation.date} interlocuteurs={interlocuteurs}/>
    {messages.filter((o)=>o.msgType!=='empty').map((message,i)=>{
      //console.log(interlocuteurs,message);
      rank++;
      const interlocuteur=interlocuteurs.find((o)=>o._id===message.from);
      let to=null;
      if (message.msgType==='missed') to=interlocuteurs.find((o)=>o._id===message.options.missed.to);
      const side= message.from===(conversation.protagoniste || portrait._id) ? 'right' : 'left';
      return step===-1 || step>rank ? <div key={message._id} className={'conversation-message conversation-message-'+side}>
        {i===0 || ( messages[i-1].from!==message.from ) ? (
          <div className='conversation-from'>
              {interlocuteur && prenom(interlocuteur.nom)}
          </div>
        ) : ''}
        {i===0 || messages[i-1].from!==message.from || messages[i-1].heure!==message.heure ? (
          <div>{message.heure}</div>
        ) : ''}
        {message.msgType==='default' && <div className='conversation-message-content'>
          <div>
            <Bulle text={formatText(message.options.default.text)} side={side}/>
            {message.options.default.failed && <div className='conversation-message-failed'>
              non distribué <span className='failed-icon'></span>
            </div>}
          </div>
        </div>}
        {message.msgType==='missed' && <div className='conversation-message-content'>
          <div className='conversation-message-missed'>
            <span className='missed-call-icon'></span> Appel {to && prenom(to.nom)}
          </div>
        </div>}

      </div> : '';
    })}
  </div>;
}
export default Conversation;
