import React, { useState, useEffect, useRef } from "react";
import "./FontLoader.scss";

function FontLoader({font,onLoaded}) {
  const [ loaded, setLoaded ] = useState(false);
  const [ fire, setFire ] = useState(false);
  const el=useRef(null);
  useEffect(()=>{
    if (el.current) {
      const currentWidth=el.current.clientWidth;
      //console.log({fontFamily:font.fontFamily,initialWidth:el.current.clientWidth});
      const handleOk=()=>{
        onLoaded();
        setLoaded(true);
        clearInterval(timer);
      }
      const timer=setTimeout(()=>{
        handleOk();
      },3000);
      if (font.finalWidth && currentWidth===font.finalWidth) {
        //console.log('font direct render');
        handleOk();
      } else {
        const observer = new MutationObserver((mutations)=>{
          if (currentWidth!==el.current.clientWidth) {
            //console.log({fontFamily:font.fontFamily,finalWidth:el.current.clientWidth});
            handleOk();
          }
        });
        observer.observe(el.current, {
          attributes: true
        });
        setTimeout(()=>setFire(true),0);
        return ()=>{
          console.log('disconnect',font);
          observer.disconnect();
        }
      }
    }
  },[font,loaded,onLoaded])
  return loaded ? null : <div className='font-loader' style={{fontFamily:fire ? font.fontFamily : null}} ref={el}>
    placeholder
  </div>;
}

export default FontLoader;
