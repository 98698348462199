import React from "react";
import "./Loader.scss";
import Lottie from 'react-lottie-player'
import animation from 'components/assets/pregenerique.json';

function Loader({loaded,needAction,onDone}) {
  return <div className={'loader'+(loaded?' loaded' : '')} onDoubleClick={onDone}>
    <div className='loader-animation'>
      <Lottie
      animationData={animation}
      play
      loop={false}
      style={{ width: '100%', height: '100%' }}
      onComplete={onDone}
      />
    </div>
    <div className='loader-progress'>
      {needAction && <div className="need-action">Click needed</div>}
      <div className="loader-bar-container">
        <div className="loader-bar" style={{width:loaded+'%'}}/>
      </div>
    </div>
  </div>;
}

export default Loader;
