import React, { useState, createContext, useEffect, useCallback } from "react";
import Conf from 'Conf';
import * as _ from 'lodash';
import axios from 'axios';

const parseCustomPath=(customPath,doc,createItem=false)=>{
  //console.log(customPath,doc);
  let p=[...customPath];
  let path=[];
  while (p.length>0) {
    let current=p[0];
    let el=path.length>0 ? _.get(doc,path) : doc;
    if (el) {
      if (Array.isArray(current)) {
        let id=current[0];
        let idKey=current[1] || '_id';
        if (Array.isArray(el)) {
          let idx=el.findIndex((o)=>o[idKey]===id);
          if (idx!==-1) path.push(idx);
          else {
            if (createItem) {
              path.push(el.length);
              el.push({[idKey]:id});
            } else {
              console.log('not found');
              return false;
            }
          }
        } else {
          console.log('not found');
        }
      } else {
        path.push(current)
      }
    } else {
      console.log('not found');
    }
    p.splice(0,1);
  }
  //console.log('parseCustomPath',customPath,path);
  return path;
};
const get=(o,path)=>{
  return _.get(o,parseCustomPath(path,o));
}

export const ModelContext = createContext({});

const ModelProvider = ({children})=>{
  const [ modele,setModele ] = useState([]);
  useEffect(()=>{
    console.log('build');
    const getData=()=>{
      const remote=Boolean(process.env.REACT_APP_REMOTE);
        (remote ? axios.post(Conf.apiUrl+'public', {}) : axios.get('/public.json'))
        .then(function (response) {
          setModele(response.data.rows);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    getData();
  }
  ,[setModele]);
  const getDoc=useCallback((docId)=>{
    return get(modele,[[docId,'id'],'doc']);
  },[modele]);
  const getCollection=useCallback((type)=>{
    return modele.filter((o)=>o.doc.type===type).map((o)=>o.doc).sort((a,b)=> a.sort-b.sort);
  },[modele]);
  const getDocPath=useCallback((docId,path)=>{
    const doc=getDoc(docId);
    return get(doc,path);
  },[getDoc]);
  return (
        <ModelContext.Provider value={{modele,getCollection,get,getDoc,getDocPath}}>
            {children}
        </ModelContext.Provider>
    );
}
export default ModelProvider;
