import React from "react";
import { initiales } from 'shared/conversationsUtils';

function Avatar({ interlocuteur, onEnter, onLeave }) {
  const handlePointerLeave=(e)=>{
    e.stopPropagation();
    onLeave();
  }
  const handlePointerEnter=(e)=>{
    e.stopPropagation();
    onEnter();
  }
  return <div
  className='conversation-interlocuteur'
  onClick={(e)=>e.stopPropagation()}
  onPointerEnter={handlePointerEnter}
  onPointerLeave={handlePointerLeave}
  >
    <div className='conversation-interlocuteur-avatar'>
      <div>{initiales(interlocuteur.nom)}</div>
    </div>
  </div>;
}
export default Avatar;
