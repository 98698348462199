import React, {useState} from 'react';
import "./Lien.scss";
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import SwiperCore, {
  Pagination
} from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/modules/pagination/pagination.scss';
import Conf from 'Conf';
import { Scrollbars } from 'react-custom-scrollbars-2';
import Modal from 'shared/components/Modal';
import MuiMarkdown from 'mui-markdown';

SwiperCore.use([Pagination]);

const Lien = ({lien,file=(url)=>url,...otherProps}) => {
  const [ preventClick,setPreventClick] = useState(false);
  let classes='lien';
  if (lien && lien.images.length>0) classes+=' has-images';
  const content=lien ? <div className={classes}>
    <div className='lien-content'>
      <Scrollbars
      style={{ position:'absolute', width: '100%', height: '100%' }}
      autoHide
      >
        <div className='lien-content-inner'>
          <h3>{lien.titre}</h3>
          <div><MuiMarkdown>{lien.texte}</MuiMarkdown></div>
        </div>
      </Scrollbars>
    </div>
    {lien && lien.images.length>0 && <div className='lien-images'>
      <Swiper
        pagination={{ clickable: true, type:'bullets' }}
        spaceBetween={0}
        slidesPerView={1}
        onTouchStart={()=>setPreventClick(true)}
        onTouchEnd={()=>setTimeout(()=>setPreventClick(false),10)}
      >
        {lien.images.map((img,i)=><SwiperSlide key={img.url+'-'+i}><img src={file(Conf.filesUrl+img.url+'-600')} alt=""/></SwiperSlide>)}
      </Swiper>
    </div>}
  </div> : null;
  return <Modal content={content} show={Boolean(lien)} {...otherProps} preventClick={preventClick}/>;
}
export default Lien;
