import React, { useContext } from 'react';
import "./MainMenu.scss";
import { SettingsContext } from "providers/SettingsProvider";
import Menu from "shared/components/Menu";
import { useNavigate } from "react-router-dom";

const MainMenu = () => {
  const { appState, setAppState } = useContext(SettingsContext);
  const navigate=useNavigate();
  const items=[
    {id:0,titre:'Chronologie'},
    {id:1,titre:'Réglages'},
    {id:2,titre:'Aide'},
    {id:3,titre:'Crédits'},
  ];
  const hideMainMenu=()=>setAppState((state)=>{return {...appState,mainMenu:{...appState.mainMenu, show:false}}});
  const goTo=(path)=>{
    hideMainMenu();
    navigate(path);
  };
  const contextMenu=appState.contextMenu ? appState.contextMenu.map((o)=>{return {
    ...o,
    onClick:()=>{
      hideMainMenu();
      o.onClick();
    }
  }}) : [];
  const topMenu=[{className:'close',onClick:hideMainMenu}];
  const bottomMenu=[{className:'home',onClick:()=>goTo('/themes')},...contextMenu];
  return <div className={'main-menu'+(appState.mainMenu.show ? ' show' : '')}>
      <div className='main-menu-inner'>
        {items.map((item)=><div key={item.id} className="main-menu-item">{item.titre}</div>)}
      </div>
      <Menu type='top' justify='flex-end' items={topMenu} fullWidth/>
      <Menu type='bottom' items={bottomMenu}/>
    </div>;
}
export default MainMenu;
