import React from "react";
import "./Bulle.scss";

function Bulle({ text,side,file=(url)=>url }) {
  return <div className={'bulle bulle-'+side}>
    <div className="background-0"></div>
    <div className="background-1"></div>
    <div className="background-2"></div>
    <div className="background-3"></div>
    <div className="text">{text}</div>
  </div>;
}
export default Bulle;
